/*
 * File: variables.scss
 * Author: Philipp Schoenhuth
 * -----
 * Last Modified: Friday, 9th June 2023
 * Last Modified By: philippschoenhuth
 * -----
 * Copyright (C) 2021 - 2023, Smart City System Parking Solutions GmbH
 * All rights reserved.
 */

/* ------------------------------------ */
/* Predefined padding */
$spaceXs: 4px;
$spaceS: 8px;
$spaceM: 16px;
$spaceL: 24px;
$spaceXl: 36px;
$spaceXxl: 48px;
/* ------------------------------------ */

$designBarTopHeight: 56px;
$designBarBottomHeight: 85px;
