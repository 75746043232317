
@use 'sass:color';
@use '@angular/material' as mat;
@use '../../custom_flex_layout';
@use '../../fonts';
@use '../../variables';

@include mat.elevation-classes();
@include mat.app-background();

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}


$custom-palette-primary: (
  100: #2C3B7D,
  200: #2C3B7D,
  300: #2C3B7D,
  400: #2C3B7D,
  500: #2C3B7D,
  600: #2C3B7D,
  700: #2C3B7D,
  800: #2C3B7D,
  900: #2C3B7D,
  contrast: (
    50: rgba(black, 0.87),
    200: rgba(black, 0.87),
    400: #dcdce8,
    // ... continues to 900
  )
);

$custom-palette-secondary: (
  100: #198cc8,
  200: #198cc8,
  300: #198cc8,
  400: #198cc8,
  500: #198cc8,
  600: #198cc8,
  700: #198cc8,
  800: #198cc8,
  900: #198cc8,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    // ... continues to 900
  )
);

$custom-palette-warn: (
  100: #C73C3C,
  200: #C73C3C,
  300: #C73C3C,
  400: #C73C3C,
  500: #C73C3C,
  600: #C73C3C,
  700: #C73C3C,
  800: #C73C3C,
  900: #C73C3C,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    // ... continues to 900
  )
);

$custom-palette-dark-grey: (
  100: #3F3F3F,
  200: #3F3F3F,
  300: #3F3F3F,
  400: #3F3F3F,
  500: #3F3F3F,
  600: #3F3F3F,
  700: #3F3F3F,
  800: #3F3F3F,
  900: #3F3F3F,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    // ... continues to 900
  )
);

$custom-palette-grey: (
  100: #DEDEDE,
  200: #DEDEDE,
  300: #DEDEDE,
  400: #DEDEDE,
  500: #DEDEDE,
  600: #DEDEDE,
  700: #DEDEDE,
  800: #DEDEDE,
  900: #DEDEDE,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    // ... continues to 900
  )
);

$custom-palette-light-grey: (
  100: #F8F8F8,
  200: #F8F8F8,
  300: #F8F8F8,
  400: #F8F8F8,
  500: #F8F8F8,
  600: #F8F8F8,
  700: #F8F8F8,
  800: #F8F8F8,
  900: #F8F8F8,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    // ... continues to 900
  )
);

$angular-primary: mat.m2-define-palette($custom-palette-primary, 400, 700, 900);
$angular-accent: mat.m2-define-palette($custom-palette-secondary, 400, 700, 900);
$angular-warn: mat.m2-define-palette($custom-palette-warn, 300);
$angular-dark-grey: mat.m2-define-palette($custom-palette-dark-grey, 300);
$angular-grey: mat.m2-define-palette($custom-palette-grey, 300);
$angular-light-grey: mat.m2-define-palette($custom-palette-light-grey, 300);

$angular-default-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $angular-primary,
        accent: $angular-accent,
        warn: $angular-warn,
        dark-grey: $angular-dark-grey,
        grey: $angular-grey,
        light-grey: $angular-light-grey
      ),
    )
);

@include mat.all-component-themes($angular-default-theme);

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
}

.color-primary {
  color: mat.m2-get-color-from-palette($angular-primary, 400);
}

.color-accent {
  color: mat.m2-get-color-from-palette($angular-accent, 400);
}



.color-warn {
  color: mat.m2-get-color-from-palette($angular-warn, 300);
}

.color-dark-grey {
  color: mat.m2-get-color-from-palette($angular-dark-grey, 300);
}

.color-grey {
  color: mat.m2-get-color-from-palette($angular-grey, 300);
}

.color-light-grey {
  color: mat.m2-get-color-from-palette($angular-light-grey, 300);
}

.background-color-primary {
  background-color: mat.m2-get-color-from-palette($angular-primary, 400);
}

.background-color-accent {
  background-color: mat.m2-get-color-from-palette($angular-accent, 400);
}

.background-color-warn {
  background-color: mat.m2-get-color-from-palette($angular-warn, 300);
}

.background-color-dark-grey {
  background-color: mat.m2-get-color-from-palette($angular-dark-grey, 300);
}

.background-color-grey {
  background-color: mat.m2-get-color-from-palette($angular-grey, 300);
}

.background-color-light-grey {
  background-color: mat.m2-get-color-from-palette($angular-light-grey, 300);
}

.background-primary {
  background: mat.m2-get-color-from-palette($angular-primary, 400);
}

.background-accent {
  background: mat.m2-get-color-from-palette($angular-accent, 400);
}

.background-warn {
  background: mat.m2-get-color-from-palette($angular-warn, 300);
}

.background-dark-grey {
  background: mat.m2-get-color-from-palette($angular-dark-grey, 300);
}

.background-grey {
  background: mat.m2-get-color-from-palette($angular-grey, 300);
}

.background-light-grey {
  background: mat.m2-get-color-from-palette($angular-light-grey, 300);
}

.design-bar {
  display: flex;
  flex-direction: row;
  height: variables.$designBarTopHeight;
  width: 100vw;
}


.page-container {
  width: calc(100vw - 2 * 10px);
  margin: 50px 10px 10px;
  height: 90svh;
  //height: calc(90vh - env(safe-area-inset-bottom, 0px));
  //top: variables.$designBarTopHeight;
  display: flex;
  flex-direction: column;
}

.landing-page-container {
  display: flex;
  flex-direction: column;
  //height: calc(100% - 2 * variables.$designBarTopHeight - 2 * 20px);
  width: calc(100vw - 2 * 20px);
  margin: 20px 20px;
  position: fixed;
  top: variables.$designBarTopHeight;
  bottom: variables.$designBarBottomHeight;
  left: 0;
  right: 0;
  overflow: auto;
  /* Add iOS safe area adjustment */
  padding-bottom: 40px;
}

/* iOS-specific fixes */
@supports (-webkit-touch-callout: none) {
  .landing-page-container {
    /* Give extra space at bottom for iOS */
    bottom: calc(variables.$designBarBottomHeight + env(safe-area-inset-bottom, 20px));

    /* Improve scrolling behavior on iOS */
    -webkit-overflow-scrolling: touch;

    /* Add a bit more padding to ensure buttons aren't cut off */
    padding-bottom: 40px;
  }
}

@font-face {
  font-family: 'Roboto';
  src: url('../../../assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300; /* Light ist normalerweise 300, nicht 200 */
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../../assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../../assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

.days-one {
  font-family: 'DaysOne', sans-serif;
  font-weight: 200;
}

/* Desktop is default, styling changes to switch to mobile version */
.landscape-mode-only {
  display: flex;
}

.portrait-mode-only {
  display: none;
}

@media screen and (orientation: portrait) {
  .landscape-mode-only {
    display: none;
  }
  .portrait-mode-only {
    display: flex;
  }
}

@media screen and (orientation: landscape) {
  .landscape-mode-only {
    display: flex;
  }
  .portrait-mode-only {
    display: none;
  }
}


@media screen and (max-width: 750px) {
  .desktop-mode-only {
    display: none;
    min-width: 0;
    max-width: 0;
    width: 0;
    min-height: 0;
    height: 0;
    max-height: 0;
    opacity: 0;
  }
  .mobile-mode-only {
    display: flex;
  }

  .page-container {
    width: calc(100vw - 2 * 10px);
    margin: 50px 10px 10px;
    height: 90svh;
    //height: calc(90vh - env(safe-area-inset-bottom, 0px));
    //top: variables.$designBarTopHeight;
    display: flex;
    flex-direction: column;
  }

  ///* This only applies to iOS devices */
  //@supports (-webkit-touch-callout: none) {
  //  .page-container {
  //    /* iOS-specific styles here */
  //    height: 70svh !important;
  //  }
  //}

  .font-size-h1 {
    font-size: 1.5rem !important;
  }

  .font-size-h2 {
    font-size: 1.1rem !important;
  }

  .font-size-h3 {
    font-size: 0.8rem !important;
  }

}

@media screen and (min-width: 750px) {
  .desktop-mode-only {
    display: flex;
  }
  .mobile-mode-only {
    display: none;
    min-width: 0;
    max-width: 0;
    width: 0;
    min-height: 0;
    height: 0;
    max-height: 0;
    opacity: 0;
  }
}

.cdk-overlay-pane {
  div.mat-mdc-menu-panel {
    max-width: none;
  }
  div.mat-mdc-menu-content {
    padding: 0;
  }
}

.design-bar-top {
  position: fixed; top: 0; left: 0; right: 0
}

.design-bar-top-right-space {
  min-width: 80px;
}

.design-bar-bottom {
  position: fixed; bottom: 0; left: 0; right: 0
}

